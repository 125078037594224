<template>
  <div class="" id="partner">
    <div class="container-fluid">
      <div class="row pt-2 pb-2">
        <h4 class="text-light fw-bold text-uppercase" style="font-family: Rockwell">Our Partners</h4>
        <div class="col-sm-12 ps-0 pe-0 slider text-center" v-if="partners!==null && partners.length>0">
          <carousel :autoplay="false" :nav="false" :dots="false" :items="6" :responsive="responsiveControls">
            <template slot="prev"><span class="fa fa-arrow-left prev"></span></template>
            <div class="m-1" v-for="(partner,key) in partners" :key="key">
              <img :src="partner.logo" alt="main-banner1" class="img-thumbnail" :title="partner.title"/>
            </div>
            <template slot="next"><span class="fa fa-arrow-right next"></span></template>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import carousel from 'vue-owl-carousel'

  export default {
    name: "Partner",
    data(){
      return{
        responsiveControls:{
          0:{items:1,nav:false},
          600:{items:3,nav:false},
          1024:{items:6,nav:false},
        },
        partners:null
      }
    },
    components:{
      carousel
    },
    beforeCreate(){
      this.$http.get('get-partners-logo').then(response => {
        this.partners = response.body;
      })
    },
    mounted() {

    }
  }
</script>

<style scoped>
  #partner{
    background-color: #BCBCBC;
  }

  .slider{
    position: relative;
  }

  .prev,.next{
    display: inline-block;
    background-color: rgba(0,0,0,0.2);
    color: white;
    padding: 10px;
    z-index: 999;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .prev{
    left: 0;
    border-radius: 0px 8px 8px 0px;
  }
  .next{
    right: 0;
    border-radius: 8px 0px 0px 8px;
  }

  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
  }

  #partner img{
    width: 100%;
    height: auto;
  }
  @media print {
    #partner{ display: none}
  }
</style>