import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueResource from 'vue-resource'
import loader from "vue-ui-preloader"
import { store } from './store/store'
import vueAwesomeCountdown from 'vue-awesome-countdown'
Vue.use(vueAwesomeCountdown, 'vac'); // Component name, `countdown` and `vac` by default
import VueCountdown from '@chenfengyuan/vue-countdown';
Vue.component(VueCountdown.name, VueCountdown);

window.$ = window.jquery = require('jquery');

require('bootstrap');
import 'bootstrap/dist/css/bootstrap.css';

Vue.config.productionTip = false;
Vue.use(VueResource);
Vue.use(loader);

//Main API URL
// Vue.http.options.root = 'https://wadmin.bdwpho.org/api/';
Vue.http.options.root = 'https://admin.bdpho.org/api/';
// Vue.http.options.root = 'http://127.0.0.1:8000/api/';
// Vue.http.headers.common['Authorization'] = 'Basic YXBpOnBhc3N3b3Jk';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
