<template>
  <div class="" id="logo">
    <div class="container-fluid">
      <div class="row" v-if="showLogo">
        <div class="col-12 text-start">
          <img :src="logo" alt="" class="logo pb-2 pt-1">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Logo",
    data(){
      return {
        logo:null,
        showLogo:false
      }
    },
    created() {
      //this.logo = JSON.parse(sessionStorage.getItem('site_info')).logo;
    }
  }
</script>

<style scoped>
  img.logo{
    height: 150px;
    width: auto;
  }
</style>