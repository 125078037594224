<template>
  <div class="" id="footer">
    <div class="container-fluid">
      <div class="row mb-0 bg-secondary text-light text-start">
        <div class="col-md-6 col-sm-12 copyright pt-2">
          &copy; {{ copyRightYear }} All rights reserved | Powered by: <a href="https://www.facebook.com/groups/338654837340138" target="_blank">bits & Bytes</a>
        </div>
        <div class="col-md-6 col-sm-12 text-end social-link">
          <a href="#" class="m-md-2 m-sm-1 twitter"><i class="fa fa-twitter"></i></a>
          <a href="https://www.facebook.com/groups/bangladeshphysicsolympiad" target="_blank" class="m-md-2 m-sm-1 facebook"><i class="fa fa-facebook"></i></a>
          <a href="#" class="m-md-2 m-sm-1 google-plus"><i class="fa fa-google-plus"></i></a>
          <a href="#" class="ms-md-2 me-md-0 m-sm-1 rss"><i class="fa fa-rss"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Footer",
    data(){
      return{
        copyRightYear:new Date().getFullYear()
      }
    },
  }
</script>

<style scoped>
  .copyright a{
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;
  }

  .copyright a:hover{
    color: navy;
  }

  .social-link a{
    color: #ffffff;
    display: inline-block;
  }

  .social-link a:hover{
    color: skyblue;
    transform: rotateY(360deg);
    transition: all 0.5s;
  }

  @media print {
    #footer{ display: none}
  }
</style>