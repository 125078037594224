<template>
  <div class="" id="address">
    <img v-if="siteInfo!==null" :src="siteInfo.logo" class="mb-3 img-thumbnail" alt="">
    <h6 class="text-uppercase fw-bold">Contact Us:</h6>
    <p v-if="siteInfo!==null">
      <strong>Address: </strong> {{siteInfo.title}}<br>
      {{siteInfo.address_line_one}} <br>
      {{siteInfo.address_line_two}} <br>
<!--      {{siteInfo.address_line_three}} <br>-->
<!--      <strong>Support:</strong> {{support}} <br>-->
<!--      <strong>Support:</strong> {{siteInfo.support}} <br>-->
<!--      <strong>Manager :</strong> {{siteInfo.manager}} <br>-->
      <strong>E-mail:</strong> {{help}}
<!--      <strong>E-mail:</strong> {{siteInfo.email}}-->
    </p>
  </div>
</template>

<script>
  export default {
    name: "Address",
    data(){
      return {
        siteInfo:null
      }
    },
    computed:{
      help(){return this.$store.getters.getHelp;},
      support(){return this.$store.getters.getTechnicalSupport;}
    },
    created() {
      this.$http.get('site-info').then(response => {
        this.siteInfo = response.body;
      });
    },
    mounted() {

    },
    updated() {

    }
  }
</script>

<style scoped>
  #address img{
    width: 400px;
    height: auto;
  }

  #address p{
    font-size: 14px;
  }
  @media print {
    #address{
      display: none;
    }
  }
</style>
