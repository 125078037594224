<template>
  <div class="" id="big-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 text-lg-start text-sm-center">
          <Address/>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 text-md-start text-sm-center">
          <quick-link/>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 text-md-start text-sm-center">
          <important-link/>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <VisitorAndFacebook/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Address from "./big-footer/Address";
  import QuickLink from "./big-footer/QuickLink";
  import ImportantLink from "./big-footer/ImportantLink";
  import VisitorAndFacebook from "./big-footer/VisitorAndFacebook";

  export default {
    name: "BigFooter",
    components:{
      Address,
      'quick-link':QuickLink,
      'important-link':ImportantLink,
      VisitorAndFacebook
    }
  }
</script>

<style scoped>
  #big-footer{
    background-color: #F2F2F2;
    padding: 30px 30px 10px 30px;
  }
</style>