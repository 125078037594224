<template>
  <div class="" id="important-link">
    <h4>Important Link</h4>
    <ul class="">
      <li class=""><router-link class="" to="/">Central Committee</router-link></li>
      <li class=""><router-link class="" to="/">Olympiads</router-link></li>
      <li class=""><router-link class="" :to="{name:'Syllabus'}">Syllabus</router-link></li>
      <li class=""><router-link class="" :to="{name:'Resources'}">Resources</router-link></li>
      <li class=""><router-link class="" to="/">About</router-link></li>
      <li class=""><router-link class="" :to="{name:'Gallery'}">Photo Gallery</router-link></li>
      <li class=""><router-link class="" to="/">Press Release</router-link></li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "ImportantLink"
  }
</script>

<style scoped>
  #important-link ul{
    list-style-type: square;
    list-style-position: inside;
  }

  #important-link ul li{

  }

  #important-link ul li a{
    text-decoration: none;
    color: #444444;
    font-weight: normal;
    font-size: 14px;
  }

  #important-link ul li a:hover{
    color: navy;
    font-weight: bold;
  }

  @media print {
    #important-link{
      display: none;
    }
  }
</style>