<template>
  <div class="" id="visitor-and-facebook">
    <h4 class="">Total Visitor</h4>
    <!-- hitwebcounter Code START -->
    <a href="https://www.hitwebcounter.com" target="_blank" class="d-block mb-3">
      <img class="img-responsive" style="width:340px; height: 50px;" :src="'https://hitwebcounter.com/counter/counter.php?page='+visitorCounter+'&amp;style=0005&amp;nbdigits=9&amp;type=page&amp;initCount=10000'" title="" alt="" >
    </a>
    <!-- hitwebcounter Code START -->
<!--    <a href="https://www.hitwebcounter.com" target="_blank" class="d-block mb-3">-->
<!--      <img class="img-responsive" style="width:340px; height: 50px;"  src="https://hitwebcounter.com/counter/counter.php?page=7895575&style=0005&nbdigits=9&type=page&initCount=1" title="Free Counter" Alt="web counter"   border="0" />-->
<!--    </a>-->
    <iframe :src="fbLikeBox" width="340" height="130" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
  </div>
</template>

<script>
  export default {
    name: "VisitorAndFacebook",
    computed:{
      fbLikeBox(){return this.$store.getters.getFBLikeBox;},
      visitorCounter(){return this.$store.getters.getVisitorCounter;}
    },
    created() {
      console.log(this.$store.getters.getVisitorCounter);
    }
  }
</script>

<style scoped>
  @media print {
    #visitor-and-facebook{
      display: none;
    }
  }
</style>