<template>
  <div class="" id="top-menu">
    <div id="nav">
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container-fluid">
          <!--This line is only loading the logo in hidden way-->
          <img :src="eventLogo" alt="Event logo" class="small-logo d-none">

          <router-link class="navbar-brand p-0" to="/"><span>{{siteTitle}}</span>
<!--            <img alt="BdPhO logo" v-if="logoStatus" class="img-thumbnail" :src="logo">-->
          </router-link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link class="nav-link" aria-current="page" :to="{name:'Home'}">Home</router-link>
              </li>

              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  About {{siteTitle}}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown1">
                  <li class="nav-item"><router-link class="dropdown-item" :to="{name:'CentralCommittee'}">Central Committee</router-link></li>
                  <li class="nav-item"><router-link class="dropdown-item" :to="{name:'Syllabus'}">Syllabus</router-link></li>
                  <li class="nav-item"><router-link class="dropdown-item" :to="{name:'PastPaper'}">Past Papers</router-link></li>
                  <li class="nav-item"><router-link class="dropdown-item" to="/resources">Resources</router-link></li>
                </ul>
              </li>

              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="participantList" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Participant List
                </a>
                <ul class="dropdown-menu" aria-labelledby="participantList">
                  <li class="dropdown-submenu" v-for="(division,key) in divisions" :key="key">
                    <a class="dropdown-item dropdown-toggle" href="#">{{division.name}}</a>
                    <ul class="dropdown-menu">
                      <li><router-link class="dropdown-item" :to="{name:'ParticipantList', params:{id:division.id, name:division.name, categoryId:4, category:'Category-A'}}">Category-A</router-link></li>
                      <li><router-link class="dropdown-item" :to="{name:'ParticipantList', params:{id:division.id, name:division.name, categoryId:5, category:'Category-B'}}">Category-B</router-link></li>
                      <li><router-link class="dropdown-item" :to="{name:'ParticipantList', params:{id:division.id, name:division.name, categoryId:6, category:'Category-C'}}">Category-C</router-link></li>
                      <li><router-link class="dropdown-item" :to="{name:'ParticipantList', params:{id:division.id, name:division.name, categoryId:7, category:'Category-D'}}">Category-D</router-link></li>
                    </ul>
                  </li>
                </ul>
              </li>

<!--              <li class="nav-item dropdown">-->
<!--                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownAchievement" role="button" data-bs-toggle="dropdown" aria-expanded="false">-->
<!--                  Achievement-->
<!--                </a>-->
<!--                <ul class="dropdown-menu" aria-labelledby="navbarDropdownPastPaper">-->
<!--                  <li class="nav-item"><router-link class="dropdown-item" to="/ipho-achievement">IPhO</router-link></li>-->
<!--                  <li class="nav-item"><router-link class="dropdown-item" to="/apho-achievement">APhO</router-link></li>-->
<!--                </ul>-->
<!--              </li>-->

              <li class="nav-item">
                <router-link class="nav-link"  :to="{name:'Schedule'}">Schedule</router-link>
              </li>

              <li class="nav-item">
                <router-link class="nav-link"  :to="{name:'Results'}">Results</router-link>
              </li>

              <li class="nav-item">
                <router-link class="nav-link"  :to="{name:'Notice'}">Notice</router-link>
              </li>

              <li class="nav-item">
                <router-link class="nav-link"  to="/organizer">Organizer</router-link>
              </li>

<!--              <li class="nav-item">-->
<!--                <router-link class="nav-link"  :to="{name:'TechFair'}">Technology Fair</router-link>-->
<!--              </li>-->

              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownGallery" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Gallery
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownGallery">
                  <li class="nav-item"><router-link class="dropdown-item" :to="{name:'Gallery'}">Photo Gallery</router-link></li>
                  <li class="nav-item"><router-link class="dropdown-item" :to="{name:'VideoGallery'}">Video Gallery</router-link></li>
                </ul>
              </li>

              <li class="nav-item">
                <router-link class="nav-link"  :to="{name:'RegistrationRules'}">Registration Rules</router-link>
              </li>

<!--              <li class="nav-item" v-if="regStat && login">-->
<!--                <router-link class="nav-link"  :to="{name:'Registration'}">Registration</router-link>-->
<!--              </li>-->

              <li class="nav-item" v-if="regStat && login">
                <a class="nav-link text-danger" :href="paymentUrl">Registration</a>
              </li>

              <li class="nav-item" v-if="!login ">
                <router-link class="nav-link"  :to="{name:'ParticipantProfile'}">Profile</router-link>
              </li>
            </ul>

            <ul class="d-flex mb-0 student-area">
              <li v-if="!login"><button @click="logout" class="btn btn-light"><i class="fa fa-sign-out"></i> Logout</button></li>
              <li v-if="login"><router-link :to="{name:'Login'}" class="btn btn-light"><i class="fa fa-user"></i> Login</router-link></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery';
  export default {
    name: "TopMenu",
    data(){
      return{
        login:true,
        eventLogo: null,
        logo:null,
        //logoStatus:false,
        favicon:null,
        paymentUrl:this.$http.options.root+'bkash/pay'
      }
    },
    props:{
      regStat: Boolean
    },
    beforeCreate(){
      if (JSON.parse(localStorage.getItem('participant')) !== null){this.login = false}else {this.login=true}
    },
    created() {
      this.eventLogo = sessionStorage.getItem('eventLogo');
      this.$http.get("get-event-code").then(function (response) {
        sessionStorage.setItem('eventCode',response.body.code);
        sessionStorage.setItem('eventLogo',response.body.logo);
      });

      if (JSON.parse(localStorage.getItem('participant')) !== null){
        this.login = false;
        this.participantInfo = JSON.parse(localStorage.getItem('participant'));
      }else {this.login=true}

      this.$http.get('site-info').then(response => {
        this.logo = response.body.logo;
        this.favicon = response.body.favicon;
      });

      this.faviconSet();
    },
    mounted(){
      if (JSON.parse(localStorage.getItem('participant')) !== null){this.login = false}else {this.login=true}

      //Sub dropdown jQuery
      $('.dropdown-menu a.dropdown-toggle').on('click', function() {
        if (!$(this).next().hasClass('show')) {
          $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        var $subMenu = $(this).next(".dropdown-menu");
        $subMenu.toggleClass('show');

        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function() {
          $('.dropdown-submenu .show').removeClass("show");
        });
        return false;
      });
    },
    updated() {
      if (JSON.parse(localStorage.getItem('participant')) !== null){this.login = false}else {this.login=true}

      //Sub dropdown jQuery
      $('.dropdown-menu a.dropdown-toggle').on('click', function() {
        if (!$(this).next().hasClass('show')) {
          $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        var $subMenu = $(this).next(".dropdown-menu");
        $subMenu.toggleClass('show');

        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function() {
          $('.dropdown-submenu .show').removeClass("show");
        });
        return false;
      });
    },
    computed:{
      divisions(){return this.$store.getters.getDivisions;},
      siteTitle(){return this.$store.getters.getSiteTitle;}
    },
    methods:{
      logout(){
        this.$http.post('participant-logout', {id:this.participantInfo.id}).then(response => {
          if (response.body.status=='success'){
            localStorage.removeItem('participant');
            localStorage.removeItem('avatar');
            this.$router.push({name:'Home'})
          }
        });
      },
      faviconSet(){
        // const favicon = document.getElementById("favicon");
        // favicon.href = this.favicon;
        console.log('favicon');
      },
    }
  }
</script>

<style scoped>
  #top-menu{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  #nav a.router-link-exact-active {
    border: 2px solid white;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  #nav .dropdown-menu a.router-link-exact-active {
    background-color: #212529;
    color: #ffffff;
  }

  .navbar-brand{
    border: none !important;
  }

  .navbar-brand img{
    width: auto;
    height: 50px;
  }

  ul.student-area{
    list-style-type: none;
  }
</style>
